import * as React from "react";
import { Box, Card, CardMedia, CircularProgress } from "@mui/material";
import { useRecordContext } from "react-admin";
import { ImageClass } from "../types";
import Image from "mui-image";

const ImagePoster = () => {
  const record = useRecordContext<ImageClass>();
  return (
    <Image
      src={record.file._url}
      showLoading
    />
  );
};

export default ImagePoster;
