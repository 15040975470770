import React, { useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useCreateSuggestionContext,
  useTranslate,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";
import { CreateDialog } from "@react-admin/ra-form-layout/esm/src";
import { contactTypeChoises, maintenanceItemTypeChoises, unitTypeChoises } from "../shared/select";
import { CountryInput } from "../shared/CountryInput";
import { MaintenanceItemTypeEnum } from "../types";


export const VendorCreateItem = () => {
  const translate = useTranslate();
  const [open, setOpen] = useState(true);
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");
  const onSuccess = (data: any) => {
    onCreate(data);
  };

  const handleClose = () => {
    onCancel();
    setValue("");
    setOpen(false);
  };

  return (
    <CreateDialog
      resource="Vendor"
      isOpen={open}
      close={handleClose}
      fullWidth
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm defaultValues={{ name: value }}>
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="name" validate={[required()]} fullWidth />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="inn" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="city" reference="City">
                  <SelectInput
                    optionText="name"
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Box>
            </Box>
            <TextInput source="legalAddress" fullWidth />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          {translate("resources.Vendor.fieldGroups.contacts")}
        </Typography>
        <ArrayInput source="contacts" label={false}>
          <SimpleFormIterator inline disableClear fullWidth disableReordering>
            <SelectInput
              source="type"
              label={translate("resources.Vendor.fields.type")}
              validate={required()}
              choices={contactTypeChoises}
              sx={{ display: "flex", flex: 1 }}
            />
            <TextInput
              source="value"
              validate={required()}
              sx={{ display: "flex", flex: 1 }}
              label={translate("resources.Vendor.fields.value")}
            />
            <TextInput
              source="description"
              validate={required()}
              sx={{ display: "flex", flex: 1 }}
              label={translate("resources.Vendor.fields.description")}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </CreateDialog>
  );
};
