import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { AuthorInput } from "../shared/AuthorInput";
import { RUBFormat } from "../shared/formatUtils";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";

const inventoryMoveFilter = [
  <ReferenceInput
    source="item"
    label="resources.MaintenanceItem.fields.name"
    reference="MaintenanceItem"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      fullWidth
      label="resources.MaintenanceItem.fields.name"
      filterToQuery={(searchText: string) => ({ searchString: searchText })}
    />
  </ReferenceInput>,

  <AuthorInput alwaysOn source="author" />,
];

export const InventoryIncomeList = () => {
  useDefineAppLocation("inventory.outcome.list");
  return (
    <List
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={inventoryMoveFilter}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <DateField source="date" />
        <TextField source="numStr" />
        <ReferenceField source="stock" reference="Stock">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="total" options={RUBFormat()} />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default InventoryIncomeList;
