import {
  SelectInput,
  useGetOne,
  SelectInputProps,
} from "react-admin";
import { Stock } from "../types";

interface RackInputProps extends SelectInputProps{
  isRequired?: boolean;
  source: string;
  stockId: string;
}

export const RackInput = (props: RackInputProps) => {
  const { data, isLoading, error } = useGetOne<Stock>("Stock", { id: props.stockId });
  return (
    <SelectInput
      {...props}
      choices={data?.racks ?? []}
      optionText="name"
      optionValue="name"
      isLoading={isLoading}
      isRequired={props.isRequired}
    />
  );
};
