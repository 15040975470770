import InventoryIncomeIcon from "@mui/icons-material/ArrowCircleDown";
import {
  DataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";

import { InventoryIncome, Maintenance, ParsePointer } from "../types";
import InventoryIncomeList from "./InventoryIncomeList";
import InventoryIncomeEdit from "./InventoryIncomeEdit";
import InventoryIncomeCreate from "./InventoryIncomeCreate";

const resource = {
  list: InventoryIncomeList,
  edit: InventoryIncomeEdit,
  create: InventoryIncomeCreate, 
  icon: InventoryIncomeIcon,
  recordRepresentation: "numStr",
};

export const inventoryIncomeLifecycleCallbacks = {
  resource: "InventoryIncome",

  beforeGetList: async (params: GetListParams, dataProvider: DataProvider) => {
    if (params.filter.item) {
      return { ...params, meta: "containsItems" };
    }
    return params;
  },

  afterRead: async (record: InventoryIncome, dataProvider: DataProvider) => {
    return {
      ...record,
      stock: record.stock?.id,
      vendor: record.vendor?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.stock = new ParsePointer("Stock", data.stock);
    if (data.vendor) {
      data.vendor = new ParsePointer("Vendor", data.vendor);
    } else {
      delete data.vendor;
    }
    if (data.date) {
      data.date = new Date(data.date);
    }
    return data;
  },
};

export default resource;
