import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { Box, Grid, Typography } from "@mui/material";
import { contactTypeChoises } from "../shared/select";

export const VendorCreateEdit = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("inventory.vendor.edit", { record });
  } else {
    useDefineAppLocation("inventory.vendor.create");
  }

  const translate = useTranslate();

  return (
    <SimpleForm>
      <Grid container width={{ xs: "100%" }} spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {translate("resources.Vendor.fieldGroups.identity")}
          </Typography>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="name" validate={[required()]} fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="inn" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ReferenceInput source="city" reference="City">
                <SelectInput
                  optionText="name"
                  fullWidth
                  validate={required()}
                />
              </ReferenceInput>
            </Box>
          </Box>
          <TextInput source="legalAddress" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom>
        {translate("resources.Vendor.fieldGroups.contacts")}
      </Typography>
      <ArrayInput source="contacts" label={false}>
        <SimpleFormIterator inline disableClear fullWidth disableReordering>
          <SelectInput
            source="type"
            label={translate("resources.Vendor.fields.type")}
            validate={required()}
            choices={contactTypeChoises}
            sx={{ display: "flex", flex: 1 }}
          />
          <TextInput
            source="value"
            validate={required()}
            sx={{ display: "flex", flex: 1 }}
            label={translate("resources.Vendor.fields.value")}
          />
          <TextInput
            source="description"
            validate={required()}
            sx={{ display: "flex", flex: 1 }}
            label={translate("resources.Vendor.fields.description")}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
