import { Divider, Stack, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import {
  InventoryRemainder,
  MaintenanceItem,
  MaintenanceItemTypeEnum,
} from "../../types";

export const InventoryRemainderAutocompleteRenderer = () => {
  const record = useRecordContext<InventoryRemainder>();
  return (
    <span>
      {record.itemObject.get("name")} {record.itemObject.get("sku")}
      <Stack direction="row" spacing={2}>
        {record.itemObject.get("type") === MaintenanceItemTypeEnum.SPARE_PART &&
          record.itemObject.get("brandStr") && (
            <Typography variant="body2" color="textSecondary">
              Марка: {record.itemObject.get("brandStr")}
            </Typography>
          )}
        {record.itemObject.get("type") === MaintenanceItemTypeEnum.SPARE_PART &&
          record.itemObject.get("modelStr") && (
            <Typography variant="body2" color="textSecondary">
              Модель: {record.itemObject.get("modelStr")}
            </Typography>
          )}
        {record.itemObject.get("countryStr") && (
          <Typography variant="body2" color="textSecondary">
            Страна: {record.itemObject.get("countryStr")}
          </Typography>
        )}
        {(record.itemObject.get("type") === MaintenanceItemTypeEnum.SPARE_PART ||
          record.itemObject.get("type") === MaintenanceItemTypeEnum.MATERIAL) &&
          record.itemObject.get("body") && (
            <Typography variant="body2" color="textSecondary">
              Кузов: {record.itemObject.get("body")}
            </Typography>
          )}
        <Typography variant="body2" color="textSecondary">
          Кол-во: {record.quantity}
        </Typography>
      </Stack>
    </span>
  );
};
