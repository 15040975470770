import InventoryOutcomeIcon from "@mui/icons-material/ArrowCircleUp";
import {
  DataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";

import { InventoryOutcome, ParsePointer } from "../types";
import InventoryOutcomeList from "./InventoryOutcomeList";
import InventoryOutcomeEdit from "./InventoryOutcomeEdit";
import InventoryOutcomeCreate from "./InventoryOutcomeCreate";

const resource = {
  list: InventoryOutcomeList,
  edit: InventoryOutcomeEdit,
  create: InventoryOutcomeCreate, 
  icon: InventoryOutcomeIcon,
  recordRepresentation: "numStr",
};

export const inventoryOutcomeLifecycleCallbacks = {
  resource: "InventoryOutcome",

  beforeGetList: async (params: GetListParams, dataProvider: DataProvider) => {
    if (params.filter.item) {
      return { ...params, meta: "containsItems" };
    }
    return params;
  },

  afterRead: async (record: InventoryOutcome, dataProvider: DataProvider) => {
    return {
      ...record,
      stock: record.stock?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.stock = new ParsePointer("Stock", data.stock);
    if (data.date) {
      data.date = new Date(data.date);
    }
    return data;
  },
};

export default resource;
