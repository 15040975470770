import { SxProps, Theme } from "@mui/material";
import ImageGallery from "react-image-gallery";
import { MaintenanceItemImage } from "../types";
import { Loading, useGetList } from "react-admin";
import "react-image-gallery/styles/css/image-gallery.css";

interface MaintenanceItemImageGalleryProps {
  itemId: string;
  sx?: SxProps<Theme>;
}

export const MaintenanceItemImageGallery = ({
  itemId,
  sx,
}: MaintenanceItemImageGalleryProps) => {
  const { data, total, isLoading, error } = useGetList("MaintenanceItemImage", {
    pagination: { page: 1, perPage: 10 },
    filter: { itemId: itemId },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    data && (
      <ImageGallery
        items={data.map((image) => {
          return {
            original: image.file.url(),
            thumbnail: image.file.url(),
          };
        })}
      />
    )
  );
};
