import { Avatar, IconButton } from "@mui/material";
import { MultiFilePreviewProps } from "./preview-multi-file";
import ImageIcon from "@mui/icons-material/Photo";

export const HasPhotoAvtar = ({
  sx,
  onRemove,
  slotProps,
  onShowGallery,
  images = [],
}: MultiFilePreviewProps) => {
  return images && images.length ? (
    <>
      <IconButton onClick={onShowGallery}>
        <ImageIcon />
      </IconButton>
    </>
  ) : null;
};
