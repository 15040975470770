import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import { FileThumbnail, FileThumbnailProps } from "./file-thumbnail";
import { SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MaintenanceItemImage } from "../types";
import { useNavigate } from "react-router";
import { useCreatePath } from "react-admin";

// ----------------------------------------------------------------------

export type UploadProps = {
  error?: boolean;
  sx?: SxProps<Theme>;
  thumbnail?: boolean;
  onDelete?: () => void;
  onUpload?: () => void;
  helperText?: React.ReactNode;
  placeholder?: React.ReactNode;
  onRemove?: (file: MaintenanceItemImage) => void;
};

export type MultiFilePreviewProps = {
  images: MaintenanceItemImage[];
  sx?: SxProps<Theme>;
  onRemove?: UploadProps["onRemove"];
  onShowGallery?: () => void;
  slotProps?: {
    thumbnail?: Omit<FileThumbnailProps, "file">;
  };
};

export function MultiFilePreview({
  sx,
  onRemove,
  slotProps,
  images = [],
}: MultiFilePreviewProps) {
  const navigate = useNavigate();
  const createPath = useCreatePath();
  return (
    <Box
      component="ul"
      sx={{
        gap: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        ...sx,
      }}
    >
      {images.map((image) => {
        const { file } = image;
        const name = file.name();

        return (
          <Box component="li" key={name} sx={{ display: "inline-flex" }}>
            <FileThumbnail
              tooltip
              imageView
              file={file}
              onRemove={() => onRemove?.(image)}
              onNavigate={() => {
                navigate(
                  createPath({ resource: 'MaintenanceItemImage', id: image.id, type: "edit" })
                );
              }}
              sx={{
                width: 80,
                height: 80,
                border: (theme) => `solid 1px ${theme.palette.grey[400]}`,
              }}
              slotProps={{ icon: { width: 36, height: 36 } }}
              {...slotProps?.thumbnail}
            />
          </Box>
        );
      })}
    </Box>
  );
}
