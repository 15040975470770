import { Grid, Typography, Box, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
  useTranslate,
  SimpleForm,
  DateInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TextInput,
  TabbedForm,
  DateTimeInput,
  NumberField,
  FunctionField,
  FormDataConsumer,
  minValue,
  SelectField,
  SelectInput,
  useGetIdentity,
  useRecordContext,
  RaRecord,
  Toolbar,
  SaveButton,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { MaintenanceCreateItem } from "../maintenance-item/MaintenanceCreateItem";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import {
  DocTypeEnum,
  Maintenance,
  MaintenanceDataItem,
  MaintenanceItem,
  MaintenanceItemTypeEnum,
  UnitTypeEnum,
} from "../types";
import { maintenanceItemTypeChoises, unitTypeChoises } from "../shared/select";
import MaintenanceItemPriceInput from "../shared/ItemPriceInput";
import MaintenanceItemUnitTypeInput from "../shared/ItemUnitTypeInput";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorInput } from "../shared/AuthorInput";
import { MaintenanceItemAutocompleteRenderer } from "./MaintenanceItemAutompleteRenderer";
import React from "react";
import { EditInDialogButton } from "@react-admin/ra-form-layout/esm/src";
import { CountryInput } from "../shared/CountryInput";
import { CarInput } from "../shared/CarInput";

const itemOptionText = <MaintenanceItemAutocompleteRenderer />;
const inputText = (choice: MaintenanceItem) =>
  `${choice.name} ${choice.sku ? `(${choice.sku})` : ""}`;
const matchSuggestion = (filter: string, choice: MaintenanceItem) => {
  return choice.name.toLowerCase().includes(filter.toLowerCase());
};

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("maintenance.maintenance.edit", { record });
  } else {
    useDefineAppLocation("maintenance.maintenance.create");
  }
  return (
    <TabbedForm defaultValues={props.defaultValues}>
      <TabbedForm.Tab label="resources.Maintenance.tabs.identity">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Maintenance.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box mr={{ xs: 0, sm: "0.5em" }}>
                <DateTimeInput source="start" validate={required()} />
              </Box>
              <Box>
                <DateTimeInput source="end" />
              </Box>
            </Box>
            <CarInput source="car"/>
            <ArrayInput source="data">
              <SimpleFormIterator
                inline
                disableClear
                disableReordering
                fullWidth
              >
                <ReferenceInput
                  source="item"
                  label={translate("resources.MaintenanceItem.fields.name")}
                  reference="MaintenanceItem"
                  sort={{ field: "name", order: "ASC" }}
                >
                  <AutocompleteInput
                    optionText={itemOptionText}
                    sx={{ display: "flex", flex: 6 }}
                    optionValue="id"
                    groupBy={(option: MaintenanceItem) =>
                      option.name.substring(0, 1).toUpperCase()
                    }
                    inputText={inputText}
                    validate={required()}
                    label={translate("resources.MaintenanceItem.fields.name")}
                    filterToQuery={(searchText: string) => ({
                      searchString: searchText,
                    })}
                    matchSuggestion={matchSuggestion}
                    create={<MaintenanceCreateItem />}
                  />
                </ReferenceInput>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest
                  }) =>
                    scopedFormData && scopedFormData.item ? (
                      <EditInDialogButton
                        inline
                        resource="MaintenanceItem"
                        id={scopedFormData.item}
                        mutationMode="optimistic"
                        ButtonProps={{
                          size: "small",
                          variant: "contained",
                          color: "primary",
                          sx: { mt: 1, p: 1 },
                        }}
                      >
                        <SimpleForm
                          resource="MaintenanceItem"
                          toolbar={
                            <Toolbar sx={{ justifyContent: "space-between" }}>
                              <SaveButton />
                            </Toolbar>
                          }
                        >
                          <Grid
                            container
                            width={{ xs: "100%", xl: "100%" }}
                            spacing={2}
                          >
                            <Grid item xs={12} md={12}>
                              <TextInput
                                source="name"
                                fullWidth
                                validate={required()}
                              />
                              <SelectInput
                                source="type"
                                fullWidth
                                choices={maintenanceItemTypeChoises}
                                validate={required()}
                              />
                              <FormDataConsumer<{
                                type: MaintenanceItemTypeEnum;
                              }>>
                                {({ formData, ...rest }) => {
                                  if (
                                    formData.type ===
                                    MaintenanceItemTypeEnum.SPARE_PART
                                  ) {
                                    return (
                                      <>
                                        <Box
                                          display={{
                                            xs: "block",
                                            sm: "flex",
                                          }}
                                        >
                                          <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: "0.5em" }}
                                          >
                                            <ReferenceInput
                                              source="brand"
                                              reference="AutoBrand"
                                              sort={{
                                                field: "name",
                                                order: "ASC",
                                              }}
                                            >
                                              <AutocompleteInput
                                                optionText="name"
                                                optionValue="id"
                                                filterToQuery={(
                                                  searchText: string
                                                ) => ({ name: searchText })}
                                              />
                                            </ReferenceInput>
                                          </Box>
                                          <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: "0.5em" }}
                                          >
                                            <FormDataConsumer<{
                                              brand: string;
                                            }>>
                                              {({
                                                formData, // The whole form data
                                                scopedFormData, // The data for this item of the ArrayInput
                                              }) =>
                                                formData.brand &&
                                                formData.brand !==
                                                  scopedFormData?.brand && (
                                                  <ReferenceInput
                                                    source="model"
                                                    reference="AutoModel"
                                                    filter={{
                                                      brand: formData.brand,
                                                    }}
                                                    sort={{
                                                      field: "name",
                                                      order: "ASC",
                                                    }}
                                                  >
                                                    <AutocompleteInput
                                                      optionText="name"
                                                      optionValue="id"
                                                      filterToQuery={(
                                                        searchText: string
                                                      ) => ({
                                                        name: searchText,
                                                      })}
                                                    />
                                                  </ReferenceInput>
                                                )
                                              }
                                            </FormDataConsumer>
                                          </Box>
                                        </Box>
                                        <TextInput source="sku" fullWidth />
                                        <TextInput source="body" fullWidth />
                                        <TextInput
                                          source="manufacturer"
                                          fullWidth
                                        />
                                        <CountryInput source="country" />
                                      </>
                                    );
                                  } else if (
                                    formData.type ===
                                    MaintenanceItemTypeEnum.MATERIAL
                                  ) {
                                    return (
                                      <>
                                        <TextInput source="sku" fullWidth />
                                        <TextInput
                                          source="manufacturer"
                                          fullWidth
                                          label={translate(
                                            "resources.MaintenanceItem.fields.manufacturer"
                                          )}
                                        />
                                        <CountryInput
                                          source="country"
                                          label={translate(
                                            "resources.MaintenanceItem.fields.country"
                                          )}
                                        />
                                      </>
                                    );
                                  }
                                }}
                              </FormDataConsumer>
                              <SelectInput
                                source="unitType"
                                fullWidth
                                choices={unitTypeChoises}
                              />
                            </Grid>
                          </Grid>
                        </SimpleForm>
                      </EditInDialogButton>
                    ) : null
                  }
                </FormDataConsumer>

                <TextInput
                  source="shop"
                  sx={{ display: "flex", flex: 1 }}
                  label={translate("resources.MaintenanceItem.fields.shop")}
                />
                <NumberInput
                  source="amount"
                  sx={{ display: "flex", flex: 1 }}
                  min={1}
                  max={200}
                  defaultValue={1}
                  label={translate("resources.MaintenanceItem.fields.amount")}
                  validate={[required(), minValue(1)]}
                />
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    if (!scopedFormData || !getSource || !scopedFormData.item) {
                      return null;
                    }
                    return (
                      <>
                        <MaintenanceItemUnitTypeInput
                          source={getSource("unitType")}
                          item={scopedFormData.item}
                          sx={{ display: "flex", flex: 1 }}
                        />
                        <MaintenanceItemPriceInput
                          source={getSource("price")}
                          sx={{ display: "flex", flex: 1 }}
                          item={scopedFormData.item}
                          label={'resources.MaintenanceItem.fields.incomePrice'}
                        />
                      </>
                    );
                  }}
                </FormDataConsumer>
                <TextInput
                  source="description"
                  resettable
                  label={translate(
                    "resources.MaintenanceItem.fields.description"
                  )}
                />
              </SimpleFormIterator>
            </ArrayInput>

            <FormDataConsumer<{ data: MaintenanceDataItem[] }>>
              {({ formData, ...rest }) => {
                return (
                  formData.data &&
                  formData.data.length > 0 && (
                    <FunctionField
                      variant="h6"
                      render={(record: Maintenance) => {
                        const totalAmount = formData.data
                          .map((a) => a.price * a.amount)
                          .reduce(function (a, b) {
                            return a + b;
                          });
                        return "Общая сумма наряда " + totalAmount + " ₽";
                      }}
                    />
                  )
                );
              }}
            </FormDataConsumer>
            <Box mt="1em" />

            <TextInput source="description" fullWidth multiline />
            <AuthorInput
              source="technician"
              sort={{ field: "name", order: "ASC" }}
              label={translate("resources.Maintenance.fields.technician")}
              sx={{ display: "flex", flex: 1 }}
            />

            <Box mb="6em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>

      {props.edit && (
        <TabbedForm.Tab label="resources.Maintenance.tabs.files">
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Maintenance.fieldGroups.files")}
            </Typography>

            <DocsReferenceInput
              source={DocTypeEnum.MAINTENANCE}
              target="maintenanceId"
            />

            <Box mb="6em" />
          </Grid>
        </TabbedForm.Tab>
      )}

      <Box mt="2em" />
    </TabbedForm>
  );
};
