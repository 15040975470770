import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  Edit,
} from "react-admin";
import { VendorCreateEdit } from "./createEdit";

export const VendorEdit = () => {
 
  return (
    <Edit mutationMode="pessimistic">
      <VendorCreateEdit edit={true} />
    </Edit>
  );
};

export default VendorEdit;
