import { Grid, Box } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { maintenanceItemTypeChoises, unitTypeChoises } from "../shared/select";
import { CountryInput } from "../shared/CountryInput";
import { MaintenanceItemTypeEnum } from "../types";
import MaintenanceItemImageReferenceInput from "../shared/MaintenanceItemImageReferenceInput";
import { MaintenanceItemImagesField } from "../cars/MaintenanceItemImagesField";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const CreateEdit = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("maintenance.maintenance_item.edit", { record });
  } else {
    useDefineAppLocation("maintenance.maintenance_item.create");
  }
  return (
    <SimpleForm>
      <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
        <Grid item xs={12} md={12}>
          <TextInput source="name" fullWidth validate={required()} />
          <SelectInput
            source="type"
            fullWidth
            choices={maintenanceItemTypeChoises}
            validate={required()}
          />
          <FormDataConsumer<{
            type: MaintenanceItemTypeEnum;
          }>>
            {({ formData, ...rest }) => {
              if (formData.type === MaintenanceItemTypeEnum.SPARE_PART) {
                return (
                  <>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <ReferenceInput
                          source="brand"
                          reference="AutoBrand"
                          sort={{ field: "name", order: "ASC" }}
                        >
                          <AutocompleteInput
                            optionText="name"
                            optionValue="id"
                            filterToQuery={filterToQuery}
                          />
                        </ReferenceInput>
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <FormDataConsumer<{ brand: string }>>
                          {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                          }) =>
                            formData.brand &&
                            formData.brand !== scopedFormData?.brand && (
                              <ReferenceInput
                                source="model"
                                reference="AutoModel"
                                filter={{ brand: formData.brand }}
                                sort={{ field: "name", order: "ASC" }}
                              >
                                <AutocompleteInput
                                  optionText="name"
                                  optionValue="id"
                                  filterToQuery={filterToQuery}
                                />
                              </ReferenceInput>
                            )
                          }
                        </FormDataConsumer>
                      </Box>
                    </Box>
                    <TextInput source="sku" fullWidth />
                    <TextInput source="article" fullWidth />
                    <TextInput source="body" fullWidth />
                    <TextInput source="manufacturer" fullWidth />
                    <CountryInput source="country" />
                    <MaintenanceItemImageReferenceInput />
                  </>
                );
              } else if (formData.type === MaintenanceItemTypeEnum.MATERIAL) {
                return (
                  <>
                    <TextInput source="sku" fullWidth />
                    <TextInput source="article" fullWidth />
                    <TextInput source="manufacturer" fullWidth />
                    <CountryInput source="country" />
                    <MaintenanceItemImageReferenceInput />
                  </>
                );
              }
            }}
          </FormDataConsumer>
          <SelectInput source="unitType" fullWidth choices={unitTypeChoises} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
