import { Box, Grid, Typography } from "@mui/material";
import {
  Create,
} from "react-admin";
import { CreateEdit } from "./CreateEdit";

const TariffCreate = () => {
  return (
    <Create redirect={"list"}>
      <CreateEdit edit={false} />
    </Create>
  );
};

export default TariffCreate;
