import React, { useState } from "react";
import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useCreateSuggestionContext,
  useTranslate,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import { CreateDialog } from "@react-admin/ra-form-layout/esm/src";
import { maintenanceItemTypeChoises, unitTypeChoises } from "../shared/select";
import { CountryInput } from "../shared/CountryInput";
import { MaintenanceItemTypeEnum } from "../types";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const MaintenanceCreateItem = () => {
  const translate = useTranslate();
  const [open, setOpen] = useState(true);
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");
  const onSuccess = (data: any) => {
    onCreate(data);
  };

  const handleClose = () => {
    onCancel();
    setValue("");
    setOpen(false);
  };

  return (
    <CreateDialog
      resource="MaintenanceItem"
      isOpen={open}
      close={handleClose}
      fullWidth
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm defaultValues={{ name: value }}>
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <TextInput
              source="name"
              fullWidth
              validate={required()}
              label={translate("resources.MaintenanceItem.fields.name")}
            />
            <SelectInput
              source="type"
              fullWidth
              choices={maintenanceItemTypeChoises}
              validate={required()}
            />
            <FormDataConsumer<{
              type: MaintenanceItemTypeEnum;
            }>>
              {({ formData, ...rest }) => {
                if (formData.type === MaintenanceItemTypeEnum.SPARE_PART) {
                  return (
                    <>
                      <Box display={{ xs: "block", sm: "flex" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <ReferenceInput
                            source="brand"
                            reference="AutoBrand"
                            sort={{ field: "name", order: "ASC" }}
                          >
                            <AutocompleteInput
                              optionText="name"
                              optionValue="id"
                              validate={required()}
                              filterToQuery={filterToQuery}
                            />
                          </ReferenceInput>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <FormDataConsumer<{ brand: string }>>
                            {({
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                            }) =>
                              formData.brand &&
                              formData.brand !== scopedFormData?.brand && (
                                <ReferenceInput
                                  source="model"
                                  reference="AutoModel"
                                  filter={{ brand: formData.brand }}
                                  sort={{ field: "name", order: "ASC" }}
                                >
                                  <AutocompleteInput
                                    optionText="name"
                                    optionValue="id"
                                    validate={required()}
                                    filterToQuery={filterToQuery}
                                  />
                                </ReferenceInput>
                              )
                            }
                          </FormDataConsumer>
                        </Box>
                      </Box>
                      <TextInput
                        source="sku"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.sku"
                        )}
                      />
                      <TextInput
                        source="article"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.article"
                        )}
                      />
                      <TextInput
                        source="body"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.body"
                        )}
                      />
                      <TextInput
                        source="manufacturer"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.manufacturer"
                        )}
                      />
                      <CountryInput
                        source="country"
                        label={translate(
                          "resources.MaintenanceItem.fields.country"
                        )}
                      />
                    </>
                  );
                } else if (formData.type === MaintenanceItemTypeEnum.MATERIAL) {
                  return (
                    <>
                      <TextInput
                        source="sku"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.sku"
                        )}
                      />
                      <TextInput
                        source="article"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.article"
                        )}
                      />
                      <TextInput
                        source="manufacturer"
                        fullWidth
                        label={translate(
                          "resources.MaintenanceItem.fields.manufacturer"
                        )}
                      />
                      <CountryInput
                        source="country"
                        label={translate(
                          "resources.MaintenanceItem.fields.country"
                        )}
                      />
                    </>
                  );
                }
              }}
            </FormDataConsumer>
            <SelectInput
              source="unitType"
              fullWidth
              choices={unitTypeChoises}
              label={translate("resources.MaintenanceItem.fields.unitType")}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </CreateDialog>
  );
};
