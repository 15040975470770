import { Edit, SimpleForm, TextInput } from "react-admin";
import { CreateEdit } from "./createEdit";

export const InventoryIncomeEdit = () => (
  <Edit mutationMode="pessimistic">
    <CreateEdit edit={true} />
  </Edit>
);

export default InventoryIncomeEdit;
