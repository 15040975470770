import { Grid, Typography, Box, IconButton } from "@mui/material";
import {
  useTranslate,
  SimpleForm,
  DateInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TextInput,
  TabbedForm,
  DateTimeInput,
  NumberField,
  FunctionField,
  FormDataConsumer,
  minValue,
  SelectField,
  SelectInput,
  useGetIdentity,
  useRecordContext,
  RaRecord,
  Toolbar,
  SaveButton,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { MaintenanceCreateItem } from "../maintenance-item/MaintenanceCreateItem";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import {
  DocTypeEnum,
  InventoryMoveDataItem,
  Maintenance,
  MaintenanceDataItem,
  MaintenanceItem,
  MaintenanceItemTypeEnum,
  UnitTypeEnum,
} from "../types";
import { maintenanceItemTypeChoises, unitTypeChoises } from "../shared/select";
import MaintenanceItemPriceInput from "../shared/ItemPriceInput";
import MaintenanceItemUnitTypeInput from "../shared/ItemUnitTypeInput";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorInput } from "../shared/AuthorInput";
import React from "react";
import { EditInDialogButton } from "@react-admin/ra-form-layout/esm/src";
import { CountryInput } from "../shared/CountryInput";
import { RackInput } from "../shared/RackSelectInput";
import { InventoryRemainderAutocompleteRenderer } from "../shared/inventory/InventoryRemainderAutompleteRenderer";
import {
  inventoryItemInputText,
  inventoryItemMatchSuggestion,
} from "../shared/inventory";

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("inventory.move.edit", { record });
  } else {
    useDefineAppLocation("inventory.move.create");
  }
  return (
    <TabbedForm defaultValues={props.defaultValues}>
      <TabbedForm.Tab label="resources.InventoryMove.tabs.identity">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.InventoryMove.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box mr={{ xs: 0, sm: "0.5em" }}>
                <DateTimeInput source="date" validate={required()} />
              </Box>
            </Box>
            <ReferenceInput source="stock" reference="Stock">
              <SelectInput
                optionText="name"
                optionValue="id"
                fullWidth
                validate={required()}
              />
            </ReferenceInput>
            <ReferenceInput source="toStock" reference="Stock">
              <SelectInput
                optionText="name"
                optionValue="id"
                fullWidth
                validate={required()}
              />
            </ReferenceInput>
            <ArrayInput source="data">
              <SimpleFormIterator
                inline
                disableClear
                disableReordering
                fullWidth
              >
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) =>
                    formData &&
                    formData.stock && (
                      <ReferenceInput
                        source="item"
                        label={translate(
                          "resources.MaintenanceItem.fields.name"
                        )}
                        reference="InventoryRemainder"
                        sort={{ field: "item.name", order: "ASC" }}
                        queryOptions={{ meta: { include: ["item"] } }}
                        filter={{ stock: formData.stock, quantity: { $gt: 0 } }}
                      >
                        <AutocompleteInput
                          optionText={
                            <InventoryRemainderAutocompleteRenderer />
                          }
                          sx={{ display: "flex", flex: 6 }}
                          optionValue="itemObject.id"
                          groupBy={(option: MaintenanceItem) =>
                            option.itemObject
                              .get("name")
                              .substring(0, 1)
                              .toUpperCase()
                          }
                          inputText={inventoryItemInputText}
                          validate={required()}
                          label={translate(
                            "resources.MaintenanceItem.fields.name"
                          )}
                          filterToQuery={(searchText: string) => ({
                            searchString: searchText,
                            // type: [
                            //   MaintenanceItemTypeEnum.SPARE_PART,
                            //   MaintenanceItemTypeEnum.MATERIAL,
                            // ],
                          })}
                          matchSuggestion={inventoryItemMatchSuggestion}
                        />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest
                  }) =>
                    scopedFormData && scopedFormData.item ? (
                      <EditInDialogButton
                        inline
                        resource="MaintenanceItem"
                        id={scopedFormData.item}
                        mutationMode="optimistic"
                        ButtonProps={{
                          size: "small",
                          variant: "contained",
                          color: "primary",
                          sx: { mt: 1, p: 1 },
                        }}
                      >
                        <SimpleForm
                          resource="MaintenanceItem"
                          toolbar={
                            <Toolbar sx={{ justifyContent: "space-between" }}>
                              <SaveButton />
                            </Toolbar>
                          }
                        >
                          <Grid
                            container
                            width={{ xs: "100%", xl: "100%" }}
                            spacing={2}
                          >
                            <Grid item xs={12} md={12}>
                              <TextInput
                                source="name"
                                fullWidth
                                validate={required()}
                              />
                              <SelectInput
                                source="type"
                                fullWidth
                                choices={maintenanceItemTypeChoises}
                                validate={required()}
                              />
                              <FormDataConsumer<{
                                type: MaintenanceItemTypeEnum;
                              }>>
                                {({ formData, ...rest }) => {
                                  if (
                                    formData.type ===
                                    MaintenanceItemTypeEnum.SPARE_PART
                                  ) {
                                    return (
                                      <>
                                        <Box
                                          display={{
                                            xs: "block",
                                            sm: "flex",
                                          }}
                                        >
                                          <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: "0.5em" }}
                                          >
                                            <ReferenceInput
                                              source="brand"
                                              reference="AutoBrand"
                                              sort={{
                                                field: "name",
                                                order: "ASC",
                                              }}
                                            >
                                              <AutocompleteInput
                                                optionText="name"
                                                optionValue="id"
                                                filterToQuery={(
                                                  searchText: string
                                                ) => ({ name: searchText })}
                                              />
                                            </ReferenceInput>
                                          </Box>
                                          <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: "0.5em" }}
                                          >
                                            <FormDataConsumer<{
                                              brand: string;
                                            }>>
                                              {({
                                                formData, // The whole form data
                                                scopedFormData, // The data for this item of the ArrayInput
                                              }) =>
                                                formData.brand &&
                                                formData.brand !==
                                                  scopedFormData?.brand && (
                                                  <ReferenceInput
                                                    source="model"
                                                    reference="AutoModel"
                                                    filter={{
                                                      brand: formData.brand,
                                                    }}
                                                    sort={{
                                                      field: "name",
                                                      order: "ASC",
                                                    }}
                                                  >
                                                    <AutocompleteInput
                                                      optionText="name"
                                                      optionValue="id"
                                                      filterToQuery={(
                                                        searchText: string
                                                      ) => ({
                                                        name: searchText,
                                                      })}
                                                    />
                                                  </ReferenceInput>
                                                )
                                              }
                                            </FormDataConsumer>
                                          </Box>
                                        </Box>
                                        <TextInput source="sku" fullWidth />
                                        <TextInput source="body" fullWidth />
                                        <TextInput
                                          source="manufacturer"
                                          fullWidth
                                        />
                                        <CountryInput source="country" />
                                      </>
                                    );
                                  } else if (
                                    formData.type ===
                                    MaintenanceItemTypeEnum.MATERIAL
                                  ) {
                                    return (
                                      <>
                                        <TextInput source="sku" fullWidth />
                                        <TextInput
                                          source="manufacturer"
                                          fullWidth
                                          label={translate(
                                            "resources.MaintenanceItem.fields.manufacturer"
                                          )}
                                        />
                                        <CountryInput
                                          source="country"
                                          label={translate(
                                            "resources.MaintenanceItem.fields.country"
                                          )}
                                        />
                                      </>
                                    );
                                  }
                                }}
                              </FormDataConsumer>
                              <SelectInput
                                source="unitType"
                                fullWidth
                                choices={unitTypeChoises}
                              />
                            </Grid>
                          </Grid>
                        </SimpleForm>
                      </EditInDialogButton>
                    ) : null
                  }
                </FormDataConsumer>
                <NumberInput
                  source="quantity"
                  sx={{ display: "flex", flex: 1 }}
                  min={1}
                  max={200}
                  defaultValue={1}
                  label={translate("resources.InventoryMove.fields.quantity")}
                  validate={[required(), minValue(1)]}
                />
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    if (!scopedFormData || !getSource || !scopedFormData.item) {
                      return null;
                    }
                    return (
                      <>
                        <MaintenanceItemUnitTypeInput
                          source={getSource("unitType")}
                          item={scopedFormData.item}
                          sx={{ display: "flex", flex: 1 }}
                        />
                      </>
                    );
                  }}
                </FormDataConsumer>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) =>
                    formData &&
                    formData.stock && (
                      <RackInput
                        source={getSource("rack")}
                        stockId={formData.stock}
                        label={translate("resources.InventoryMove.fields.rack")}
                      />
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) =>
                    formData &&
                    formData.toStock && (
                      <RackInput
                        source={getSource("toRack")}
                        stockId={formData.toStock}
                        label={translate(
                          "resources.InventoryMove.fields.toRack"
                        )}
                      />
                    )
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>

            <Box mt="1em" />

            <TextInput source="description" fullWidth multiline />

            <Box mb="6em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>

      {props.edit && (
        <TabbedForm.Tab label="resources.InventoryMove.tabs.files">
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.InventoryMove.fieldGroups.files")}
            </Typography>

            <DocsReferenceInput
              source={DocTypeEnum.INVENTORY_MOVE}
              target="inventoryMoveId"
            />

            <Box mb="6em" />
          </Grid>
        </TabbedForm.Tab>
      )}

      <Box mt="2em" />
    </TabbedForm>
  );
};
