import CarIcon from "@mui/icons-material/CarRental";
import { ParseDataProvider } from "../data-provider";
import { Inventory, InventoryRemainder } from "../types";
import { GetListParams } from "react-admin";
import { count } from "console";

export const inventotuRemaindersLifecycleCallbacks = {
  resource: "InventoryRemainder",

     afterRead: async (record: InventoryRemainder, dataProvider: ParseDataProvider) => {
    
    return {
      ...record,
      stock: record.stock?.id,
      item: record.item?.id,
      itemObject: record.item,
      country: record.country?.id,
      brand: record.brand?.id,
      model: record.model?.id,
    };
  },


};
