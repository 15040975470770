import {
  ActTypeEnum,
  AdditionalServiceRewardTypeEnum,
  AdditionalServiceTypeEnum,
  BodyTypeEnum,
  BookingSourceEnum,
  CarColors,
  CarEventTypeEnum,
  CarPassortTypeEnum,
  CarSideEnum,
  CarStatusEnum,
  ContactTypeEnum,
  CustomerTypeEnum,
  DocTemplateTypeEnum,
  DriveTypeEnum,
  EquipmentConditionEnum,
  ExtraDocTypeEnum,
  FuelTypeEnum,
  GenderEnum,
  InventoryIncomeTypeEnum,
  InventoryOutcomeTypeEnum,
  MaintenanceItemTypeEnum,
  MaintenanceStatusEnum,
  OrderTypeEnum,
  PartnerTypeEnum,
  RelationshipEnum,
  TransactionDirectionEnum,
  TransmissionTypeEnum,
  UnitTypeEnum,
  UserNotificationTypeEnum,
  VehicleTypeEnum,
  WheelDriveEnum,
} from "../types";

export const engineCapacityChoises = [
  {
    id: 700,
    name: "0.7",
  },
  { id: 1000, name: "1.0" },
  { id: 1200, name: "1.2" },
  { id: 1300, name: "1.3" },
  { id: 1400, name: "1.4" },
  { id: 1500, name: "1.5" },
  { id: 1600, name: "1.6" },
  { id: 1700, name: "1.7" },
  { id: 1800, name: "1.8" },
  { id: 2000, name: "2.0" },
  { id: 2200, name: "2.2" },
  { id: 2400, name: "2.4" },
  { id: 2500, name: "2.5" },
  { id: 2700, name: "2.7" },
  { id: 3000, name: "3.0" },
  { id: 3500, name: "3.5" },
  { id: 4000, name: "4.0" },
  { id: 4400, name: "4.4" },
  { id: 4700, name: "4.7" },
  { id: 5000, name: "5.0" },
];

export const carColorsChoises = (
  Object.keys(CarColors) as Array<keyof typeof CarColors>
).map((key) => ({ id: key, name: `custom.carColor.${key}` }));

export const wheelDriveChoises = (
  Object.keys(WheelDriveEnum) as Array<keyof typeof WheelDriveEnum>
).map((key) => ({ id: key, name: `custom.wheelDrive.${key}` }));

export const bodyTypeChoises = (
  Object.keys(BodyTypeEnum) as Array<keyof typeof BodyTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.bodyType.${key}`,
  };
});

export const vehicleTypeChoises = (
  Object.keys(VehicleTypeEnum) as Array<keyof typeof VehicleTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.vehicleType.${key}`,
  };
});

export const transmissionTypeChoises = (
  Object.keys(TransmissionTypeEnum) as Array<keyof typeof TransmissionTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.transmissionType.${key}`,
  };
});

export const driveTypeChoises = (
  Object.keys(DriveTypeEnum) as Array<keyof typeof DriveTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.driveType.${key}`,
  };
});

export const fuelTypeChoises = (
  Object.keys(FuelTypeEnum) as Array<keyof typeof FuelTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.fuelType.${key}`,
  };
});

export const carStatusChoises = (
  Object.keys(CarStatusEnum) as Array<keyof typeof CarStatusEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.carStatus.${key}`,
  };
});

export const orderTypeChoises = (
  Object.keys(OrderTypeEnum) as Array<keyof typeof OrderTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.orderType.${key}`,
  };
});

export const customerTypeChoises = (
  Object.keys(CustomerTypeEnum) as Array<keyof typeof CustomerTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.customerType.${key}`,
  };
});

export const genderChoises = (
  Object.keys(GenderEnum) as Array<keyof typeof GenderEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.gender.${key}`,
  };
});

export const contactTypeChoises = (
  Object.keys(ContactTypeEnum) as Array<keyof typeof ContactTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.contactType.${key}`,
  };
});

export const unitTypeChoises = (
  Object.keys(UnitTypeEnum) as Array<keyof typeof UnitTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.unitType.${key}`,
  };
});

export const carPassportTypeChoises = (
  Object.keys(CarPassortTypeEnum) as Array<keyof typeof CarPassortTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.carPassportType.${key}`,
  };
});

export const extraDocTypeChoises = (
  Object.keys(ExtraDocTypeEnum) as Array<keyof typeof ExtraDocTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.extraDocType.${key}`,
  };
});

export const additionalServiceTypeChoises = (
  Object.keys(AdditionalServiceTypeEnum) as Array<
    keyof typeof AdditionalServiceTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.additionalServiceType.${key}`,
  };
});

export const additionalServiceRewardTypeChoises = (
  Object.keys(AdditionalServiceRewardTypeEnum) as Array<
    keyof typeof AdditionalServiceRewardTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.additionalServiceRewardType.${key}`,
  };
});

export const maintenanceStatusChoises = (
  Object.keys(MaintenanceStatusEnum) as Array<
    keyof typeof MaintenanceStatusEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.maintenanceStatus.${key}`,
  };
});

export const bookingSourceChoises = (
  Object.keys(BookingSourceEnum) as Array<keyof typeof BookingSourceEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.bookingSource.${key}`,
  };
});

export const maintenanceItemTypeChoises = (
  Object.keys(MaintenanceItemTypeEnum) as Array<
    keyof typeof MaintenanceItemTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.maintenanceItemType.${key}`,
  };
});

export const relationshipChoises = (
  Object.keys(RelationshipEnum) as Array<keyof typeof RelationshipEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.relationship.${key}`,
  };
});

export const transactionDirectionChoises = (
  Object.keys(TransactionDirectionEnum) as Array<
    keyof typeof TransactionDirectionEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.transactionDirection.${key}`,
  };
});

export const carEventTypeChoises = (
  Object.keys(CarEventTypeEnum) as Array<keyof typeof CarEventTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.carEventType.${key}`,
  };
});

export const userNotificationTypeChoises = (
  Object.keys(UserNotificationTypeEnum) as Array<
    keyof typeof UserNotificationTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.userNotificationType.${key}`,
  };
});

export const actTypeChoises = (
  Object.keys(ActTypeEnum) as Array<keyof typeof ActTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.actType.${key}`,
  };
});

export const equipmentConditionChoises = (
  Object.keys(EquipmentConditionEnum) as Array<
    keyof typeof EquipmentConditionEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.equipmentCondition.${key}`,
  };
});

export const carSideChoises = (
  Object.keys(CarSideEnum) as Array<keyof typeof CarSideEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.carSide.${key}`,
  };
});

export const partnerTypeChoises = (
  Object.keys(PartnerTypeEnum) as Array<keyof typeof PartnerTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.partnerType.${key}`,
  };
});

export const docTemplateTypeChoises = (
  Object.keys(DocTemplateTypeEnum) as Array<keyof typeof DocTemplateTypeEnum>
).map((key) => {
  return {
    id: key,
    name: `custom.docTemplateType.${key}`,
  };
});

export const inventoryIncomeTypeChoises = (
  Object.keys(InventoryIncomeTypeEnum) as Array<
    keyof typeof InventoryIncomeTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.inventoryIncomeType.${key}`,
  };
});

export const inventoryOutcomeTypeChoises = (
  Object.keys(InventoryOutcomeTypeEnum) as Array<
    keyof typeof InventoryOutcomeTypeEnum
  >
).map((key) => {
  return {
    id: key,
    name: `custom.inventoryOutcomeType.${key}`,
  };
});
