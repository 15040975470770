import { ArrayInput, Create, SimpleForm, SimpleFormIterator, TextInput, required } from "react-admin";

export const StockCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
      <TextInput source="address" fullWidth />
      <ArrayInput source="racks">
        <SimpleFormIterator
          fullWidth
          disableClear
          getItemLabel={(index) => `№ ${index + 1}`}
        >
          <TextInput
            source="name"
            fullWidth
            label="resources.Stock.fields.rack"
          />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="description" fullWidth />
    </SimpleForm>
  </Create>
);

export default StockCreate;
