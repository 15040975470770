import { CreateInDialogButton } from "@react-admin/ra-form-layout/esm/src";
import {
  ReferenceManyField,
  WithRecord,
  SimpleForm,
  TextInput,
  required,
  FileField,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
  DateField,
  ImageField,
  Toolbar,
  SaveButton,
  ImageInput,
  WithListContext,
  useDelete,
} from "react-admin";
import { DocTypeEnum, MaintenanceItemImage } from "../types";
import React from "react";
import { MultiFilePreview } from "../maintenance-item-image/preview-multi-file";

const MaintenanceItemImageReferenceInput = () => {
  const translate = useTranslate();
  const [deleteOne, { isLoading, error }] = useDelete();

  const onRemove = (file: MaintenanceItemImage) => {
    deleteOne("MaintenanceItemImage", { id: file.id });
  };
  
  return (
    <ReferenceManyField
      reference="MaintenanceItemImage"
      target="itemId"
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <WithListContext
        render={({ data }) => (
          <MultiFilePreview
            sx={{ ml: 0, mr: 0 }}
            images={data as MaintenanceItemImage[]}
            onRemove={onRemove}
          />
        )}
      />
      <WithRecord
        render={(record) => (
          <CreateInDialogButton
            fullScreen
            record={{ itemId: record.id }}
            label={translate(
              "resources.MaintenanceItemImage.actions.uploadImages"
            )}
            ButtonProps={{
              size: "small",
              fullWidth: true,
              variant: "contained",
              sx: { mt: 1, p: 1 },
            }}
            sx={{ mb: "6em" }}
          >
            <SimpleForm>
              <ImageInput
                source="file"
                label={translate("resources.MaintenanceItemImage.fields.file")}
                validate={required()}
                multiple
                accept={"image/jpg,image/jpeg,image/heic,image/heif"}
                maxSize={10000000}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleForm>
          </CreateInDialogButton>
        )}
      />
    </ReferenceManyField>
  );
};

export default MaintenanceItemImageReferenceInput;
