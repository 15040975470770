import InventoryMoveIcon from "@mui/icons-material/SwapHorizontalCircle";
import {
  DataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";

import { InventoryMove, ParsePointer } from "../types";
import InventoryMoveList from "./InventoryMoveList";
import InventoryMoveEdit from "./InventoryMoveEdit";
import InventoryMoveCreate from "./InventoryMoveCreate";

const resource = {
  list: InventoryMoveList,
  edit: InventoryMoveEdit,
  create: InventoryMoveCreate, 
  icon: InventoryMoveIcon,
  recordRepresentation: "numStr",
};

export const inventoryMoveLifecycleCallbacks = {
  resource: "InventoryMove",

  beforeGetList: async (params: GetListParams, dataProvider: DataProvider) => {
    if (params.filter.item) {
      return { ...params, meta: "containsItems" };
    }
    return params;
  },

  afterRead: async (record: InventoryMove, dataProvider: DataProvider) => {
    return {
      ...record,
      stock: record.stock?.id,
      toStock: record.toStock?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.stock = new ParsePointer("Stock", data.stock);
    data.toStock = new ParsePointer("Stock", data.toStock);
    if (data.date) {
      data.date = new Date(data.date);
    }
    return data;
  },
};

export default resource;
