import VendorIcon from "@mui/icons-material/Store";

import VendorList from "./VendorList";
import VendorCreate from "./VendorCreate";
import VendorEdit from "./VendorEdit";
import { ParsePointer, Vendor } from "../types";
import {
  DataProvider,
  GetListParams,
  GetManyReferenceParams,
} from "react-admin";
import { ParseDataProvider } from "src/data-provider";

const resource = {
  list: VendorList,
  create: VendorCreate,
  edit: VendorEdit,
  icon: VendorIcon,
  recordRepresentation: "name",
};

export const vendorLifecycleCallbacks = {
  resource: "Vendor",

  afterRead: async (record: Vendor, dataProvider: ParseDataProvider) => {
    return {
      ...record,
      city: record.city?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    data.city = new ParsePointer("City", data.city);
    return data;
  },
};

export default resource;
