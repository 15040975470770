import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";

export const StockEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" fullWidth/>
      <TextInput source="address" fullWidth />
      <ArrayInput source="racks">
        <SimpleFormIterator
          fullWidth
          disableClear
          getItemLabel={(index) => `№ ${index + 1}`}
        >
          <TextInput source="name" fullWidth label="resources.Stock.fields.rack"/>
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="description" fullWidth />
    </SimpleForm>
  </Edit>
);

export default StockEdit;
