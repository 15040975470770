import { DatesSetArg } from '@fullcalendar/core';
import { add, sub, set } from 'date-fns';

/**
 * By default, return an interval of 3 months around now (1 month before, 2 months after)
 * unless the user requires a larger interval.
 *
 * This minimizes queries while navigating.
 */
export const getFilterValuesFromInterval = (
    dateInfo?: DatesSetArg,
    filterValues: any = {} // eslint-disable-line @typescript-eslint/no-unused-vars
): any => {
    const now = set(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
    const nowMinus1Month = sub(now, { months: 1 });
    const nowPlus2Months = add(now, { months: 2 });
    return !dateInfo ||
        (dateInfo.start > nowMinus1Month && dateInfo.end < nowPlus2Months)
        ? {
              ...filterValues,
              start: {
                $gte: { __type: "Date", iso: nowMinus1Month.toISOString() },
                $lte: { __type: "Date", iso: nowPlus2Months.toISOString() },
              },
          }
        : {
              ...filterValues,
              start: {
                $gte: { __type: "Date", iso: dateInfo.startStr},
                $lte: { __type: "Date", iso: dateInfo.endStr},
              },
          };
};
