import { SimpleForm, TextInput, Create } from "react-admin";
import { CreateEdit } from "./createEdit";

export const InventoryIncomeCreate = () => (
  <Create>
    <CreateEdit edit={false} />
  </Create>
);

export default InventoryIncomeCreate;
