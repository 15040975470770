
import {
  Create,
} from "react-admin";
import { VendorCreateEdit } from "./createEdit";

export const VendorCreate = () => {
  return (
    <Create redirect="list">
      <VendorCreateEdit edit={false} />
    </Create>
  );
};

export default VendorCreate;
