import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import {
  Icon,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { MaintenanceItemImage } from "src/types";
import { useCreatePath } from "react-admin";
import { useNavigate } from "react-router";

export const fileThumbnailClasses = {
  root: "mnl__file__thumbnail__root",
  img: "mnl__file__thumbnail__img",
  icon: "mnl__file__thumbnail__icon",
  removeBtn: "mnl__file__thumbnail__remove__button",
  downloadBtn: "mnl__file__thumbnail__download__button",
};

export type FileThumbnailProps = StackProps & {
  tooltip?: boolean;
  file: Parse.File;
  imageView?: boolean;
  sx?: SxProps<Theme>;
  onRemove?: () => void;
  onNavigate?: () => void;
  slotProps?: {
    img?: SxProps<Theme>;
    icon?: SxProps<Theme>;
    removeBtn?: SxProps<Theme>;
    downloadBtn?: SxProps<Theme>;
  };
};

export function FileThumbnail({
  sx,
  file,
  tooltip,
  onRemove,
  onNavigate,
  imageView,
  slotProps,
  ...other
}: FileThumbnailProps) {
  const renderImg = (
    <Box
      component="img"
      src={file.url()}
      className={fileThumbnailClasses.img}
      sx={{
        width: 1,
        height: 1,
        objectFit: "cover",
        borderRadius: "inherit",
        ...slotProps?.img,
      }}
      onClick={() => {
        onNavigate?.();
      }}
    />
  );

  const renderContent = (
    <Stack
      component="span"
      className={fileThumbnailClasses.root}
      sx={{
        width: 36,
        height: 36,
        flexShrink: 0,
        borderRadius: 1.25,
        alignItems: "center",
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        ...sx,
      }}
      {...other}
    >
      {renderImg}

      {onRemove && (
        <RemoveButton
          onClick={onRemove}
          className={fileThumbnailClasses.removeBtn}
          sx={slotProps?.removeBtn}
        />
      )}
    </Stack>
  );

  if (tooltip) {
    return (
      <Tooltip
        arrow
        title={file.name()}
        slotProps={{
          popper: {
            modifiers: [{ name: "offset", options: { offset: [0, -12] } }],
          },
        }}
      >
        {renderContent}
      </Tooltip>
    );
  }

  return renderContent;
}

export function RemoveButton({ sx, ...other }: IconButtonProps) {
  const theme = useTheme();
  return (
    <IconButton
      size="small"
      sx={{
        p: 0.15,
        top: 4,
        right: 4,
        position: "absolute",
        color: theme.palette.common.white,
        bgcolor: theme.palette.grey[300],
        "&:hover": {
          bgcolor: theme.palette.grey[500],
        },
        ...sx,
      }}
      {...other}
    >
      <CloseIcon width={12} />
    </IconButton>
  );
}
