import {
  AutocompleteInput,
  Datagrid,
  DatagridConfigurable,
  DateField,
  ExportButton,
  Filter,
  FilterButton,
  FilterForm,
  FormDataConsumer,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SearchInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  WithListContext,
  WithRecord,
} from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  maintenanceItemTypeChoises,
  unitTypeChoises,
} from "../../../shared/select";
import { MaintenanceItemImage, MaintenanceItemTypeEnum } from "../../../types";
import { HasPhotoAvtar } from "../../../maintenance-item-image/has-photo-avatar";
import { useState } from "react";
import { MaintenanceItemImageGallery } from "../../../maintenance-item-image/image-gallery";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";

const filters = [
  <ReferenceInput
    source="item"
    reference="MaintenanceItem"
    sort={{ field: "name", order: "ASC" }}
    filter={{
      type_neq: MaintenanceItemTypeEnum.SERVICE,
    }}
    alwaysOn
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      fullWidth
      sx={{ width: 400 }}
      filterToQuery={(searchText: string) => ({ searchString: searchText })}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="country"
    reference="Country"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      fullWidth
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="stock"
    reference="Stock"
    sort={{ field: "name", order: "ASC" }}
    alwaysOn={true}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      fullWidth
      filterToQuery={(searchText: string) => ({ searchString: searchText })}
    />
  </ReferenceInput>,
  <SelectInput
    source="type"
    label="resources.MaintenanceItem.fields.type"
    choices={maintenanceItemTypeChoises}
  />,
  <ReferenceInput
    label="resources.MaintenanceItem.fields.brand"
    source="brand"
    reference="AutoBrand"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      label="resources.MaintenanceItem.fields.brand"
      optionText="name"
      optionValue="id"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
  <FormDataConsumer source="model" alwaysOn>
    {({ formData, ...restOfTheProps }) =>
      formData.brand && (
        <ReferenceInput
          key={formData.brand}
          source="model"
          reference="AutoModel"
          filter={{ brand: formData.brand }}
          sort={{ field: "name", order: "ASC" }}
          label="resources.MaintenanceItem.fields.model"
        >
          <AutocompleteInput
            label="resources.MaintenanceItem.fields.model"
            optionText="name"
            optionValue="id"
            filterToQuery={(searchText: string) => ({ name: searchText })}
          />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>,
  <TextInput source="body" label={"resources.MaintenanceItem.fields.body"} />,
];

const RemaindersListActions = () => (
  <TopToolbar>
    <FilterButton filters={filters} />
    <SelectColumnsButton preferenceKey="inventroy.remainders.datagrid" />
  </TopToolbar>
);

const RemaindersList = () => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  useDefineAppLocation("inventory.report.remainders");
  const handleGalleryOpen = (selectedItemId: string) => {
    setItemId(selectedItemId);
    setIsGalleryOpen((prev) => !prev);
  };
  const handleDialogClose = () => setIsGalleryOpen(false);
  return (
    <>
      <Dialog open={isGalleryOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display={{ xs: "block", sm: "flex" }}>
            <MaintenanceItemImageGallery itemId={itemId} />
          </Box>
        </DialogContent>
      </Dialog>
      <List
        filters={filters}
        resource="InventoryRemainder"
        exporter={false}
        actions={<RemaindersListActions />}
      >
        <DatagridConfigurable
          omit={["brand", "model", "sku", "manufacturer", "article", "country"]}
          preferenceKey="inventroy.remainders.datagrid"
          rowClick={false}
          bulkActionButtons={false}
          sort={{ field: "updatedAt", order: "ASC" }}
        >
          <ReferenceField
            source="item"
            reference="MaintenanceItem"
            label={"resources.InventoryRemainder.fields.item"}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="country" reference="Country">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="brand" reference="AutoBrand">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="model" reference="AutoModel">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="stock" reference="Stock">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="article" />
          <TextField source="manufacturer" />
          <TextField source="sku" />
          <TextField source="rack" />
          <SelectField source="unitType" choices={unitTypeChoises} />
          <NumberField source="quantity" />
          <ReferenceManyField
            reference="MaintenanceItemImage"
            target="itemId"
            source="item"
            label="resources.InventoryRemainder.fields.images"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <WithListContext
              render={({ data, isLoading }) => (
                <HasPhotoAvtar
                  sx={{ ml: 0, mr: 0 }}
                  images={data as MaintenanceItemImage[]}
                  onShowGallery={() => handleGalleryOpen(data[0].itemId)}
                />
              )}
            />
          </ReferenceManyField>
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default RemaindersList;
