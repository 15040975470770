import { List, Datagrid, TextField, SearchInput } from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";

export const VendorList = () => {
  useDefineAppLocation("inventory.vendor");
  return (
    <List exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default VendorList;
